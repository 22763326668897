import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {InstanceDataValue} from '../../universal-data-entry/models/Typings';
import {IdentifierModel} from '../models/identifier-model';
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';


//Refactored datepicker - should replace DatepickerComponentComponent in whole app when tested

@Component({
  selector: 'con-date-picker',
  template: `
      <div *ngIf="dateObject" class="input-group">
          <input class="form-control"
                 placeholder="yyyy-mm-dd"
                 name="model"
                 [ngClass]="{ 'is-invalid': !isDateValid}"
                 [(ngModel)]="model"
                 ngbDatepicker
                 #d="ngbDatepicker"
                 (ngModelChange)="onDateChange($event)"
                 (dateSelect)="onDateSelect($event)"
          >

          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                  <con-icon-by-name [iconName]="'calendar'"></con-icon-by-name>
              </button>
          </div>

          <button *ngIf="!isDateValid"
                  class="btn p-0 quantity-button d-flex align-items-top"
                  type="button"
                  ngbPopover="Date is not valid!"
                  container="body"
                  [openDelay]="300"
                  [closeDelay]="500"
                  triggers="mouseenter:mouseleave">
              <con-icon-by-name class="align-self-center px-1"
                                [iconName]="'exclamation'"
                                style="color: red;"></con-icon-by-name>
          </button>


      </div>
  `,
  styles: ['input.is-invalid {background-image: none}']
})
export class DatePickerComponent implements OnInit {
  @Input() dateObject: InstanceDataValue;
  @Output() dateEmitter = new EventEmitter<[boolean, IdentifierModel, InstanceDataValue]>();
  private validDateSnapshot: string;
  private id = new IdentifierModel();
  public model: NgbDateStruct | null;
  public isDateValid: boolean;


  constructor(public parserFormatter: NgbDateParserFormatter) {
  }

  ngOnInit(): void {
    this.setDate(this.dateObject.value);
  }

  private updateDateObject(newDateString: string) {
    if (newDateString) {
      this.dateObject.value = this.isDateValid ? newDateString : "";
    } else this.dateObject.value = "";
  }

  private setDate(date: string): void {
    if (date) {
      let dateArr = date.split("-").map((i) => parseInt(i, 10));
      this.model = new NgbDate(dateArr[0], dateArr[1], dateArr[2]);
      this.isDateValid = true;
    } else this.model = null;
    this.isDateValid = true;
  }

  public onDateChange(value: string | NgbDateStruct): void {
    let dateString: string;
    if (typeof value === 'string' || value instanceof String) {
      if (value.length > 9) {
        this.setDate(this.validDateSnapshot);
        this.isDateValid = true;
        return;
      }
      this.isDateValid = false;
      dateString = value as string;

    } else {
      dateString = this.parserFormatter.format(value);
      this.validDateSnapshot = dateString;
      this.isDateValid = true;
    }
    this.updateDateObject(dateString);
    this.dateEmitter.emit([this.isDateValid, this.id, this.dateObject])
  }

  public onDateSelect(payload) {
  }
}

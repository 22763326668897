import { Component, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DocProcessService } from 'app/doc-process/sub-modules/doc-process-common/services/doc-process.service';
import { EntitiesService, EntityDescription } from '../../../../../entities/services/entities.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, pairwise } from 'rxjs/operators';
import { InstanceDataKiid, TaxonomyContextPeriod } from 'app/doc-process/sub-modules/universal-data-entry/models/Typings';
import { Query } from 'app/doc-process/sub-modules/doc-process-common/services/methods.service';
import { HTTPMethod, HTTPResponse } from 'app/doc-process/sub-modules/doc-process-common/models/api';
import { ShareClassesData, ShareClassesFields, InstanceDataFieldsItem, TableData, FieldType, InstanceDataFields } from 'app/doc-process/sub-modules/doc-process-common/models/fields';
import { MaterialTableComponent } from 'app/doc-process/sub-modules/doc-process-common/components/material-table/material-table.component';
import { ValidationTaskDetailTableData } from 'app/doc-process/sub-modules/doc-process-common/models/table-data';
import { SelectedFieldsService } from '../../services/selected-fields.service';
import { CreateFieldsService } from '../../services/create-fields.service';
import { TableService } from 'app/doc-process/sub-modules/doc-process-common/services/table.service';
import { SelectedInstancesService } from 'app/doc-process/sub-modules/universal-data-entry/services/selected-instances.service';
import {DocProcessProjectName, SelectedProjectService} from 'app/doc-process/sub-modules/universal-data-entry/services/selected-project.service';
import { UtilService } from '../../services/util.service';
import {TaxonomyTypeEnum} from '../../../universal-data-entry/models/taxonomy-type-enum';
import {ZoomService} from "../../services/zoom.service";

@Component({
  templateUrl: './doc-process-task-view.component.html',
  styleUrls: ['./doc-process-task-view.component.scss']
})
export class DocProcessTaskView<T extends InstanceDataKiid & ShareClassesData & ShareClassesFields, D extends T & InstanceDataFieldsItem & TableData & ValidationTaskDetailTableData> implements OnInit, OnDestroy {

  public fetchingData = true;
  public fetchingInstanceData = true;
  public operationLoading = false;

  public responseMessage: string;
  public externalMetaData: EntityDescription[];
  public taskId: number;

  public docProcessSubscription: Subscription;

  public tableData$: Subject<any> = new Subject<any>();
  public instanceEntityName = 'projectInstanceTable';
  public preserveSelection = false;
  public projectId = null;

  public refreshExternalData: Subject<any> = new Subject<any>()

  public shareClassesTableData: TableData[] = []
  public instanceDataTableData: TableData[] = [];

  public closeResult: string;

  public validationInstances = { current_fields: [], current_instances: [] }
  private fieldsAndInstanceIds: any = {fields: [], instance_ids: []}

  @ViewChild('instanceDataTable') instanceDataTable: MaterialTableComponent<T,D>
  @ViewChild('shareClassesTable') shareClassesTable: MaterialTableComponent<T,D>
  private isTaskInstancesFiltered: boolean;

  get TaxonomyContextPeriodKeysArray() {
    return new UtilService().ToStringArray(TaxonomyContextPeriod);
  }

  get defaultRadioButtonChoice(): TaxonomyContextPeriod {
    return this.docProcessService.newsLabelingDefaultPeriod.getValue()
  };

  set defaultRadioButtonChoice(val: TaxonomyContextPeriod) {
    this.docProcessService.newsLabelingDefaultPeriod.next(val)
  };

  get defaultTaxonomyTypeChoice(): TaxonomyTypeEnum {
    return this.docProcessService.fundamentalsDefaultTaxonomyType.getValue()
  };

  set defaultTaxonomyTypeChoice(val: TaxonomyTypeEnum) {
    this.docProcessService.fundamentalsDefaultTaxonomyType.next(val)
  };

  get TaxonomyTypeEnum() {
    return TaxonomyTypeEnum
  }

  constructor(
    private docProcessService: DocProcessService,
    private entitiesService: EntitiesService,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private selectedFieldsService: SelectedFieldsService<T,D>,
    private createFieldsService: CreateFieldsService<T>,
    private tableService: TableService,
    private selectedInstancesService: SelectedInstancesService,
    private selectedProjectService: SelectedProjectService,
    public utilService: UtilService,
    public zoomService: ZoomService,
  ) {


  }

  get fieldType(): typeof FieldType {
    return FieldType;
  }
  get filteredFields() {
    return this.selectedFieldsService.filterOutMultipleSharedClassesEntries(this.fieldsAndInstanceIds.fields, this.fieldsAndInstanceIds.instance_ids)
  }
  get filteredInstances(): Array<number> {
    return this.fieldsAndInstanceIds.instance_ids
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.taskId = params.task_id;
      this.projectId = params.project_id
    })

    this.fetchingData = true;
    if (this.taskId) { this.docProcessService.getData({params: this.taskId}, Query.GetTaskOverview, HTTPMethod.Get) }
    this.docProcessSubscription = this.docProcessService.apiResponse.subscribe(({type, data}: HTTPResponse)  => {
      switch (type) {
        case Query.GetTaskOverview: {
          const instanceFields: InstanceDataFields = data.data;
          //@ts-ignore
          const instanceData: T = (({ InvestmentMandate, OngoingChargesDate, PublicationDate, SRRI, Language, ...rest }) =>
            ({ InvestmentMandate, OngoingChargesDate, PublicationDate, SRRI, Language, ...rest}))(instanceFields) as T;
          const shareClassesData: T = instanceFields.ShareClasses as  T
          this.instanceDataTableData = this.createFieldsService.createInstanceDataFieldsBody(instanceData, FieldType.InstanceData)
          this.shareClassesTableData = this.createFieldsService.createInstanceDataFieldsBody(shareClassesData, FieldType.ShareClasses)
          this.externalMetaData = data.table_meta;
          this.fetchingData = false;
          setTimeout(() => {this.tableService.changedTableData.next(true)}, 0)
        } break;
        case Query.GetTaskInstancesOverview: {
          if (data.data.length <= 0 && this.isTaskInstancesFiltered === false) {
            this.toastr.warning("Oops! There are no instances left in this task. Redirecting to project home.", 'Empty Task');
            this.navigateToProjectView();
          }
          this.fetchingInstanceData = false;
          this.tableData$.next(data);
        } break;
        case Query.GetTaskInstances: {
          this.docProcessService.taskInstancesSubset.next(data)
          this.router.navigate(['annotate'], {
            queryParams: { }, queryParamsHandling: 'merge', relativeTo: this.route, skipLocationChange: false
          })
        } break;
        case Query.SubmitValidationTask: {
          const state = data.state;
          if (state === 'success') {
            this.responseMessage = data.return;
            this.toastr.success(this.responseMessage, 'Submit data');
          }
        } break;
        case Query.AutoConfirm:
        case Query.ClearConfirmations:
        case Query.RemoveInstancesFromTask:
        case Query.RejectInstances:
        case Query.RestoreInstanceData: {
          this.docProcessService.getData({params: this.taskId}, Query.GetTaskOverview, HTTPMethod.Get);
          this.closeResult = null;
          this.operationLoading = false;
          this.responseMessage = data.return;
          if (type === Query.RemoveInstancesFromTask) {
            this.responseMessage += ".<\/br>Number of instances removed: " + this.validationInstances.current_instances.length
          }
          else if (type === Query.RejectInstances) {
            this.responseMessage += ".<\/br>Number of instances rejected: " + this.validationInstances.current_instances.length
          }
          this.validationInstances.current_fields = []
          this.validationInstances.current_instances = []
          this.toastr.success(this.responseMessage, type, {enableHtml: true });
          this.refreshExternalData.next()
        } break;
      }
    })
  }
  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise(),
      ).subscribe((event: any[]) => {
      if (event[0].urlAfterRedirects.split('/').length >= 5) {
        this.fetchingData = true;
        setTimeout(() => {
          this.fetchingData = true;

        }, 1000)
      }
    });
    this.docProcessService.getData({params: this.taskId}, Query.GetTaskOverview, HTTPMethod.Get)
  }

  ngOnDestroy() {
    this.docProcessSubscription.unsubscribe();
    localStorage.removeItem('validation-task-state')
    this.selectedInstancesService.addInstanceMetadata(null)
    this.selectedInstancesService.addselectedInstanceIds(null)
  }
  loadData(event) {
    if (!!event.updates.find(update => update.param.indexOf('q:') !== -1))
      this.isTaskInstancesFiltered = true
    else
      this.isTaskInstancesFiltered = false
    this.docProcessService.getData({params: this.taskId, options: event}, Query.GetTaskInstancesOverview, HTTPMethod.Get)
  }

  // Selecting fields handlers
  onSelectedFields(data: D) {
    const {type, value} = data
    if (value) {
      this.fieldsAndInstanceIds.fields = this.selectedFieldsService.handleSelectedFields(data, this.fieldsAndInstanceIds.fields)
      this.validationInstances.current_fields = this.selectedFieldsService.handleSelectedFields(data, this.validationInstances.current_fields)
    }
  }
  onSelectedAllFields(data: string[], type: FieldType) {

    this.fieldsAndInstanceIds.fields = this.selectedFieldsService.handleSelectedAllFields(type, data, this.fieldsAndInstanceIds.fields)
    this.validationInstances.current_fields = this.selectedFieldsService.handleSelectedAllFields(type, data, this.validationInstances.current_fields)
  }

  // EntityList code
  selectedInstance(event) {
    const currentInstanceIds = this.validationInstances.current_instances;
    const currentInstanceIndex = currentInstanceIds.indexOf(event.id);
    if (currentInstanceIndex >= 0) {
      currentInstanceIds.splice(currentInstanceIndex, 1);
      return;
    }
    currentInstanceIds.push(event.id);
    this.validationInstances.current_instances = currentInstanceIds;
  }
  setSelectedIds(instance) {
    if (instance) {
      const existingInstanceIndex = this.fieldsAndInstanceIds.instance_ids.indexOf(instance.id)
      const fieldExistsInFields = existingInstanceIndex > -1
      fieldExistsInFields ?
        this.fieldsAndInstanceIds.instance_ids.splice(existingInstanceIndex, 1) :
        this.fieldsAndInstanceIds.instance_ids.push(instance.id)
    }
  }
  setAllInstanceIds(instanceEntities) {
    if (instanceEntities.length) {
      this.fieldsAndInstanceIds.instance_ids = instanceEntities.map(instance => instance.id)
      this.validationInstances.current_instances = instanceEntities.map(instance => instance.id)
    } else {
      this.fieldsAndInstanceIds.instance_ids = []
      this.validationInstances.current_instances  = []
    }
  }

  startValidation() {
    if ((!this.validationInstances.current_fields.length) && this.isDataFieldsWidgetVisible()) { return; }
    const instancesToValidate = new Object(this.validationInstances);
    this.docProcessService.getData({params: this.taskId, body: instancesToValidate}, Query.GetTaskInstances, HTTPMethod.Post)
  }

  submitData() {
    this.docProcessService.getData({params: this.taskId}, Query.SubmitValidationTask, HTTPMethod.Get)// submitValidationTask(this.taskId);
  }

  autoIgnore() {
    const payload = this.selectedFieldsService.filterOutMultipleSharedClassesEntries(this.fieldsAndInstanceIds.fields, this.fieldsAndInstanceIds.instance_ids)
    this.docProcessService.getData({params: this.taskId, body: payload}, Query.AutoConfirm, HTTPMethod.Post )// autoIgnore(this.taskId, payload)
    this.fieldsAndInstanceIds = {fields: [], instance_ids: []}

  }
  clearConfirmationTasks() {
    const payload = this.selectedFieldsService.filterOutMultipleSharedClassesEntries(this.fieldsAndInstanceIds.fields, this.fieldsAndInstanceIds.instance_ids)
    this.docProcessService.getData({params: this.taskId, body: payload}, Query.ClearConfirmations, HTTPMethod.Post )// clearConfirmationTasks(this.taskId, payload)
    this.fieldsAndInstanceIds = {fields: [], instance_ids: []}

  }
  restoreInstanceData() {
    const payload = this.selectedFieldsService.filterOutMultipleSharedClassesEntries(this.fieldsAndInstanceIds.fields, this.fieldsAndInstanceIds.instance_ids)
    this.docProcessService.getData({params: this.taskId, body: payload}, Query.RestoreInstanceData, HTTPMethod.Post )// restoreInstanceData(this.taskId, payload)
    this.fieldsAndInstanceIds = {fields: [], instance_ids: []}
  }
  private removeInstanceFromTask() {
    const payload = this.validationInstances.current_instances
    this.docProcessService.getData({params: payload}, Query.RemoveInstancesFromTask, HTTPMethod.Get)
  }
  private rejectInstances() {
    const payload = this.validationInstances.current_instances
    this.docProcessService.getData({params: payload}, Query.RejectInstances, HTTPMethod.Get)
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.operationLoading = true;
      this.closeResult = 'Running operation, please wait...'
      switch(reason) {
        case 'autoIgnore': return this.autoIgnore();
        case 'clearConfirmationTasks': return this.clearConfirmationTasks();
        case 'restoreInstanceData': return this.restoreInstanceData();
        case 'removeInstanceFromTask': return this.removeInstanceFromTask();
        case 'rejectInstances': return this.rejectInstances();
      }
      this.closeResult = null
      this.operationLoading = false
    });
  }

  public getSelectedProject() {
    return this.selectedProjectService.project;
  }

  public isKiidSubtype(): boolean {
    if (this.fetchingData) return false;
    if (this.getSelectedProject() === DocProcessProjectName.Kiid) return true;
    if (this.getSelectedProject() === DocProcessProjectName.KiidPriip) return true;
    if (this.getSelectedProject() === DocProcessProjectName.KiidMetadata) return true;
    return false;
  }

  public isDataFieldsWidgetVisible(): boolean {
    if (this.fetchingData) return false;
    if (this.getSelectedProject() === DocProcessProjectName.PrNewsLabeling) return false;
    if (this.getSelectedProject() === DocProcessProjectName.TopicClassification) return false;
    if (this.getSelectedProject() === DocProcessProjectName.Calendar) return false;
    if (this.getSelectedProject() === DocProcessProjectName.FundamentalsPageLabeling) return false;
    if (this.getSelectedProject() === DocProcessProjectName.IncomingReports) return false;
    return true;
  }

  public isDefaultPeriodWidgetVisible(): boolean {
    if (this.fetchingData) return false;
    if (this.getSelectedProject() === DocProcessProjectName.PrNewsLabeling) return true;
    return false;
  }

  public isFullSizeInstanceTable() {
    if (this.isDefaultPeriodWidgetVisible() || this.isDataFieldsWidgetVisible() || this.isDefaultFundamentalsTaxonomyTypeWidgetVisible())
      return false
    return true
  }

  public isDefaultFundamentalsTaxonomyTypeWidgetVisible(): boolean {
    if (this.getSelectedProject() === DocProcessProjectName.FundamentalsPageLabeling) return true;
    return false;
  }

  private navigateToProjectView() {
    this.router.navigate(['../..'], {
      relativeTo: this.route, skipLocationChange: false
    });
  }
}

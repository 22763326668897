import { Injectable } from '@angular/core';
import { FieldType, InstanceTableData, ShareClassesData, ShareClassesFields, InstanceDataFieldsItem, TableData } from 'app/doc-process/sub-modules/doc-process-common/models/fields';
import { ValidationTaskDetailTableData } from 'app/doc-process/sub-modules/doc-process-common/models/table-data';

@Injectable({
    providedIn: 'root'
})
export class SelectedFieldsService<T, D extends T & InstanceDataFieldsItem & TableData & ValidationTaskDetailTableData> {
    constructor() {}

    handleSelectedAllFields(type: FieldType, arr: string[], origArr: Array<Pick<D, 'name'>>) {
        if (arr.length) {
          if (type === FieldType.InstanceData) { return [...new Set([...origArr, ...arr.map(field => field)])] }
          if (type === FieldType.ShareClasses) { return [...new Set([...origArr, ...arr.map(field => `${FieldType[FieldType.ShareClasses]}:${field}`)])] }
        } else {
          if (type === FieldType.InstanceData) { return origArr.filter(field => (field as unknown as string).split(':')[0] === FieldType[FieldType.ShareClasses]) }
          if (type === FieldType.ShareClasses) { return origArr.filter(field => (field as unknown as string).split(':')[0] !== FieldType[FieldType.ShareClasses]) }
        }
    }

    handleSelectedFields({type, name}: D, arr: string[]) {
        const fieldName = type === FieldType.ShareClasses ? `${FieldType[FieldType.ShareClasses]}:${name}` : name;
        const taskOperationFieldInstanceIndex = arr.indexOf(fieldName)
        taskOperationFieldInstanceIndex > -1 ?
            arr.splice(taskOperationFieldInstanceIndex, 1) :
            arr.push(fieldName)
        return [...new Set(arr)];
    }

    filterOutMultipleSharedClassesEntries(fieldsArr: string[], instancesArr: string[]) {
        const filteredOutFields = [...new Set(fieldsArr.map(field => {
          const fieldIsShareClass = field.split(':')[0] === FieldType[FieldType.ShareClasses]
          if (fieldIsShareClass) { return  FieldType[FieldType.ShareClasses] }
          return field
        }))]
        return {fields: filteredOutFields, instance_ids: instancesArr};
    }
}

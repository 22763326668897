import { Component } from '@angular/core';
import { DocProcessProjectName, SelectedProjectService } from '../../../sub-modules/universal-data-entry/services/selected-project.service';
import { CanDeactivateComponent } from '../../../sub-modules/universal-data-entry/interfaces/can-deactivate-component';
import { DocProcessService } from '../../../sub-modules/doc-process-common/services/doc-process.service';

@Component({
  selector: 'con-data-entry-view-router',
  template: '<dp-data-entry-page></dp-data-entry-page>',
})
export class DataEntryViewRouterComponent implements CanDeactivateComponent {
  constructor(public selectedProjectService: SelectedProjectService, private docProcessService: DocProcessService) {}


  canDeactivate(): boolean {
    return !this.docProcessService.unsavedData.getValue();
  }
}

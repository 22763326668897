import { Injectable } from '@angular/core';
import { TaxonomyDataValue } from '../../universal-data-entry/models/Typings';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export default class NewsSanityCheckService {
  public scalarDifferenceThreshold = 0.5;

  public isDecimalRegexp: RegExp;

  constructor(private utilService: UtilService) {
    this.isDecimalRegexp = new RegExp(
      /((\d)((\.\d{3},)|(,\d{3}\.)|(\s\d{3}(,|\.)))(\d{1,}))|((\D|^)(\d{0,3})(\.|,)(\d{1,2}|\d{4,})($|\D))|((\D|^)(0?)(\.|,)(\d{1,}))/
    );
  }

  public checkSanity(annotations: Array<TaxonomyDataValue>): boolean {
    if (annotations.length < 2) {
      return true;
    }
    const scalarValues: Array<number> =
      this.getScalarAnnotationValues(annotations);
    const sanityResult: boolean = this.compareScalarValues(scalarValues);

    return sanityResult;
  }

  private getScalarAnnotationValues(
    annotations: Array<TaxonomyDataValue>
  ): Array<number> {
    const scalarValues: Array<number> = [];

    annotations.forEach((annotation) => {
      const annotationNumericalValue = this.parseAmountFromStr(
        annotation.value
      );
      const annotationQuantityMultiplier = this.utilService.quantityMultiplier(
        annotation.quantity
      );
      const annotationAmount =
        annotationNumericalValue * annotationQuantityMultiplier;
      scalarValues.push(annotationAmount);
    });

    return scalarValues;
  }

  private compareScalarValues(scalarValues: Array<number>): boolean {
    const firstValue = scalarValues[0];
    const result: boolean =
      scalarValues.slice(1).filter((scalarValue) => {
        return (
          Math.abs(scalarValue - firstValue) /
            Math.min(scalarValue, firstValue) <
          this.scalarDifferenceThreshold
        );
      }).length ===
      scalarValues.length - 1;
    return result;
  }

  public parseAmountFromStr(moneyAmountInStr: string, isDecimalOverwrite?: boolean): number {
    let isDecimal: boolean;
    if (isDecimalOverwrite === true || isDecimalOverwrite === false) {
      isDecimal = isDecimalOverwrite;
    }
    else {
      let detectIfDecimal: boolean = this.isDecimalRegexp.test(moneyAmountInStr);
      isDecimal = detectIfDecimal
    }

    let amount: number;

    if (isDecimal) {
      amount = NewsSanityCheckService.parseAmountForDecimal(moneyAmountInStr);
    } else {
      amount =
        NewsSanityCheckService.parseAmountForNonDecimal(moneyAmountInStr);
    }
    return amount;
  }

  private static parseAmountForNonDecimal(moneyAmountInStr: string): number {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const moneyAmountWithoutNonDigits: string =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      moneyAmountInStr.replaceAll(/\D/g, '');
    const amount: number = +moneyAmountWithoutNonDigits;
    return amount;
  }

  private static parseAmountForDecimal(moneyAmountInStr: string): number {
    let amountBeforeDecimal = '';
    let amountAfterDecimal = '';
    let isIteratingAfterDecimalPoint = true;
    let haveIteratedADigit = false;

    for (let i = moneyAmountInStr.length - 1; i >= 0; i--) {
      const currentChar = moneyAmountInStr[i];

      if ([',', '.'].includes(currentChar) && haveIteratedADigit) {
        isIteratingAfterDecimalPoint = false;
        continue;
      }

      if (!NewsSanityCheckService.isDigit(currentChar)) {
        continue;
      } else {
        haveIteratedADigit = true;
      }

      if (isIteratingAfterDecimalPoint) {
        amountAfterDecimal = currentChar + amountAfterDecimal;
      } else {
        amountBeforeDecimal = currentChar + amountBeforeDecimal;
      }
    }

    const moneyAmountInt = Number(
      amountBeforeDecimal.concat('.').concat(amountAfterDecimal)
    );

    return moneyAmountInt;
  }

  private static isDigit(char: string) {
    const isDigitRegexp = new RegExp(/\d/);
    return isDigitRegexp.test(char);
  }
}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { UtilService } from './util.service';
import { Headers, HTTPMethod, ResponseType } from '../models/api';
import { Query } from './methods.service';
import {InstanceDataNews, TaxonomyContextPeriod} from '../../universal-data-entry/models/Typings';
import {ToastrService} from 'ngx-toastr';
import {TaxonomyTypeEnum } from '../../universal-data-entry/models/taxonomy-type-enum';
import {map, tap} from 'rxjs/operators';
import {Task} from '../../universal-data-entry/models/Task';
import {DataEntryTaxonomyDetails} from '../../universal-data-entry/models/data-entry-taxonomy-details';
import {Select} from '@ngxs/store';
import { TaskDataEntryState } from '../../universal-data-entry/state-management/states';
import {FundamentalsAnnotation} from '../models/fields';

@Injectable({
  providedIn: 'root'
})
export class DocProcessService {
  public apiResponse = new Subject<{data, type}>();
  public apiError = new Subject();
  public taskInstancesSubset = new BehaviorSubject(null);
  public newsLabelingDefaultPeriod: BehaviorSubject<TaxonomyContextPeriod>;
  public fundamentalsDefaultTaxonomyType: BehaviorSubject<TaxonomyTypeEnum>;
  public unsavedData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public taskId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  @Select(TaskDataEntryState.selectTaskId) taskId$: Observable<number>

  constructor(
    private utilService: UtilService,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) {
    this.initializeDefaultPeriod()
    this.initializeDefaultTaxonomyType()
    this.unsavedData.next(false)
    this.taskId$.subscribe(taskId => {
      this.taskId.next(taskId)
    })
  }

  private initializeDefaultPeriod() {
    let defaultPeriodInCache = localStorage.getItem('dp-news-labeling-default-period');
    let defaultPeriod;

    if (defaultPeriodInCache) {
      defaultPeriod = defaultPeriodInCache;
    } else {
      defaultPeriod = TaxonomyContextPeriod.ThreeMonth;
    }

    this.newsLabelingDefaultPeriod = new BehaviorSubject<TaxonomyContextPeriod>(defaultPeriod);
    this.newsLabelingDefaultPeriod.subscribe((period: TaxonomyContextPeriod) => {
      localStorage.setItem('dp-news-labeling-default-period', period);
    });
  }

  private initializeDefaultTaxonomyType(): void {
    this.fundamentalsDefaultTaxonomyType = new BehaviorSubject<TaxonomyTypeEnum>(TaxonomyTypeEnum.WholeReportDocument);
  }

  async getData(headers: Headers, method: Query, httpMethod: HTTPMethod, responseType?: ResponseType): Promise<any> {
      return this.apiService.sendHttpRequest(headers, method, httpMethod, responseType)
        .then( response => {
          this.apiResponse.next({data: response, type: method})
          return response
        })
        .catch((e) => {
          const responseMessage = e.data?.exception;
          this.toastr.error(responseMessage, e.data?.state, { timeOut: 100000 });

          if (e.type === "Unknown" && e.data?.state === "error" && e.data?.exception === "All instances must have the same taxonomy type") {
            setTimeout(() => {location.reload()}, 5500)
          }
        })
  }

  public getTaskInstances(taskId: number): Task {
    let taskInstance = this.taskInstancesSubset.getValue();

    if (!!taskInstance) {
      this.storeTaskInstance(taskInstance);
      return taskInstance
    }

    const cachedTaskInstance = this.fetchTaskInstance();
    if (cachedTaskInstance?.task_id !== taskId)
      return null;

    return cachedTaskInstance
  }

  private fetchTaskInstance() {
    return JSON.parse(localStorage.getItem('dp-instance-handler-most-recent-taskInstance'));
  }

  private storeTaskInstance(lastTaskInstance) {
    localStorage.setItem('dp-instance-handler-most-recent-taskInstance', JSON.stringify(lastTaskInstance));
  }

  public displaySaveCompletedToastr(data) {
    const state = data.state;
    if (state === 'success') {
      let responseMessage = "Task data saved successfully.";
      responseMessage += "<\/br>Number of instances saved: " + data.return.length
      this.toastr.success(responseMessage, 'Save data', {enableHtml: true, newestOnTop: true, onActivateTick: true });
    }
  }

  public displaySourceInstanceInvalidError() {
    this.toastr.error('The previous instance is either nonexistent or from a different issuer.', 'Source Instance Invalid');
  }

  public displayResponseError(response) {
    this.toastr.error(response, 'Error', {enableHtml: true, newestOnTop: true, onActivateTick: true, timeOut:5000 });
  }

  public static filterInstancesBySubmissionCheckbox(instances: { [p: number]: (InstanceDataNews | DataEntryTaxonomyDetails | FundamentalsAnnotation[]) }, instanceSubmissionCheckboxStatus: {[r: number]: boolean}) {
    function removeNotCheckedInstancesFromList() {
      for (let instanceId of Object.keys(instances)) {
        if (instanceSubmissionCheckboxStatus[instanceId] !== true) {
          delete instances[instanceId]
        }
      }
    }

    for (let instanceId of Object.keys(instanceSubmissionCheckboxStatus)) {
      if (instanceSubmissionCheckboxStatus[instanceId] === true) {
        removeNotCheckedInstancesFromList();
        return
      }
    }
  }

  getTaskInstances$(taskId: Observable<number>): Observable<Task> {
    return taskId.pipe(
      map((taskId) => this.getTaskInstances(taskId) ),
      tap( (taskInstances) => {if (!taskInstances) this.navigateToTaskView()} )
    )
  }

  private navigateToTaskView() {
    this.router.navigateByUrl(    UtilService.getUpperRouteUrl(window.location.pathname), {});
  }

  public onHelpIconClick() {

  }
}


import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {InstanceDefinition} from 'app/doc-process/sub-modules/doc-process-common/models/fields';
import {Observable} from 'rxjs';
import {ZoomService} from './zoom.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  getDocument({InstanceDocument}: InstanceDefinition): SafeResourceUrl {
    const {show_page, doc_content} = InstanceDocument
    const blob = this.b64toBlob(doc_content, 'application/pdf')
    const url = window.URL.createObjectURL(blob);
    const src = this.domSanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0&navpanes=0&scrollbar=1&statusbar=0&messages=0&page=' + show_page)
    return src
  }

  getDocumentObservable({InstanceDocument}: InstanceDefinition): Observable<SafeResourceUrl> {
    const {show_page, doc_content} = InstanceDocument
    const blob = this.b64toBlob(doc_content, 'application/pdf')
    const url = window.URL.createObjectURL(blob);
    return this.zoomService.zoomMagnification.pipe(
      map((val) => {
      const src = this.domSanitizer.bypassSecurityTrustResourceUrl(url + '#toolbar=0&navpanes=0&scrollbar=1&statusbar=0&messages=0&page=' + show_page + '&zoom=' + ( val))
      return src
      }),
    )
  }

  public static parsePageNumberFromUrl(newResourceUrl$: Observable<any>): Observable<number> {
    return newResourceUrl$.pipe(
      map((newResourceUrl) => {
        const urlParams = new URLSearchParams(newResourceUrl?.changingThisBreaksApplicationSecurity);
        const page = urlParams.get('page')
        return Number(page)
      })
    )
  }

  getDocumentString({InstanceDocument}: InstanceDefinition, sanitize: boolean = false): string {
    const {show_page, doc_content} = InstanceDocument
    let src = this.b64DecodeUnicode(doc_content)
    src = src.replace(/\s*<strong[^>]*>[\S\s]*?<\/strong>\s*/g, str => {
      const elm = document.createElement('strong')
      elm.innerHTML = str
      return elm.innerText
    })
    src = src.replace(/\s*<ix:nonnumeric[^>]*>[\S\s]*?<\/ix:nonnumeric>\s*/g, str => {
      const elm = document.createElement('div')
      elm.innerHTML = str
      return elm.innerText
    })

    src = src.replace(/\s*<span[^>]*>[\S\s]*?<\/span>\s*/g, str => {
      const elm = document.createElement('span')
      elm.innerHTML = str
      return elm.innerText
    })

    if (sanitize)
      src = this.domSanitizer.sanitize(SecurityContext.HTML, src);
    else
      src = this.domSanitizer.sanitize(SecurityContext.HTML, this.domSanitizer.bypassSecurityTrustHtml(src))

    return src
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(escape(window.atob( str )));
    // Going backwards: from bytestream, to percent-encoding, to original string.
    // return decodeURIComponent(atob(str).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));
  }


  b64toBlob(b64Data, contentType, sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  constructor(
    private domSanitizer: DomSanitizer,
    private zoomService: ZoomService,
  ) { }
}

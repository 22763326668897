import {
  Instance,
  InstanceDataKiid as InstanceDataKiid,
  InstanceDataNews,
  InstanceDataTopicClassification,
  InstanceDataValue
} from '../../universal-data-entry/models/Typings';
import { SafeResourceUrl } from '@angular/platform-browser';
import {DecimalSeparator} from './decimal-separator';
import {Observable} from 'rxjs';

export interface InstanceDataFields {
  InvestmentMandate: InstanceDataFieldsItem;
  OngoingChargesDate: InstanceDataFieldsItem;
  PublicationDate: InstanceDataFieldsItem;
  SRRI: InstanceDataFieldsItem;
  ShareClasses: ShareClassesFields;
  '3M': InstanceDataFieldsItem;
  '6M': InstanceDataFieldsItem;
  '9M': InstanceDataFieldsItem;
  FY: InstanceDataFieldsItem;
  FY_out: InstanceDataFieldsItem;
}

export interface ShareClassesFields {
    EntryCharge:    InstanceDataFieldsItem
    ExitCharge:     InstanceDataFieldsItem
    ISIN:           InstanceDataFieldsItem
    OngoingCharges: InstanceDataFieldsItem
    PerformanceFee: InstanceDataFieldsItem
}

export interface InstanceDefinition {
    InstanceId: number
    InstanceInfo: Pick<Instance, 'instance_id' | 'issuer_name'>
    InstanceDocument: InstanceDocument
    InstanceDataKiid: InstanceDataKiid
    InstanceDataNews: InstanceDataNews
    InstanceDataTopicClassification: InstanceDataTopicClassification
    InstanceDocumentAnnotation: InstanceDocumentAnnotations
    Instance: Instance
}

export type InstanceDocumentAnnotations = NewsLabelingAnnotation

export interface NewsLabelingAnnotation {
    instance_id: number;
    annotations: NewsAnnotation[];
}

export interface NewsAnnotation {
    fields: Array<NewsAnnotationField>
    conf: number;
    annot_id: number;
    spans: NewsSpan[];
}

export interface NewsAnnotationField {
    context: NewsContext;
    field: string
}
export interface NewsContext {
    period: string;
    quantity: string;
    sign: string;
}
export interface NewsSpan {
    xref: string;
    offsets: number[];
    text: string;
    fields?: Array<NewsAnnotationField>
}

export interface InstanceDocument {
    instance_id: number
    doc_content: string
    doc_id: number
    doc_type: string
    show_page: number
    src: SafeResourceUrl | string
    documentSafeResourceUrl: SafeResourceUrl
    documentSafeResourceUrl$: Observable<any>;
    documentString: string
}


export interface InstanceDataFieldsItem {
    total: number,
    confirmed_count: number,
    value_count: number,
    confirmed: 0
    value: 0
}

export interface TableData {
    name: string,
    value: string,
    confirmed: string,
    type: FieldType,
    selected: boolean
}

export interface CalendarEventsTableData {
  date: string,
  event_id: number,
  event_sub_type: string,
  event_type: string,
  time: string,
  updated_at: string,
  comments: any[],
}

export type InstanceTableData = Omit<InstanceDataFields, 'ShareClasses'>
export type ShareClassesData = ShareClassesFields

export enum FieldType {
    InstanceData,
    ShareClasses
}

export enum CurrentFields { // TODO rename
    InvestmentMandate,
    OngoingChargesDate,
    PublicationDate,
    SRRI,
    ISIN,
    EntryCharge,
    ExitCharge,
    OngoingCharges,
    PerformanceFee,
    Language,
    CarriedInterest,
    OtherOngoingCosts,
    TransactionCosts,
    Objective,
    InvestorTargetGroup,
    RecHoldingPeriod,
    DocumentType,
    BondType,
    Country,
    Currency,
    Duration,
    InvestmentType,
    Rating,
    Region,
    Sector,
    TopHoldings,
}

export interface FundamentalsAnnotation {
  fields: Array<FundamentalsField>
  conf: number;
  annot_id: number;
  spans: NewsSpan[];
}

export interface FundamentalsField {
  field: string
  context: {sign: string, subtract: boolean, statementId?: number, decimalSeparator?: DecimalSeparator, quantity?: any, scalarValue: number}
}



import {tap, startWith, switchMap, combineLatest, debounceTime, take} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';

import { EntitiesService, EntityDescription } from '../../services/entities.service';

import { Subject, Subscription, of } from 'rxjs';



import { snakeCase } from 'change-case';
import { InsideComponent } from 'app/inside.component';
import { SelectedInstancesService } from 'app/doc-process/sub-modules/universal-data-entry/services/selected-instances.service';
import { TranslationService } from 'app/utility/services/translation.service';
import {FileTokenService} from '../../../auth/services/file-token.service';
import {ApiSettings} from '../../../settings.class';

@Component({
    selector: 'con-entity-list',
    templateUrl: './entity-list.component.html'
})

export class EntityListComponent implements OnChanges, OnInit {
    @Input() entityName: string;
    @Input() customCompanyReportFileView: boolean = false;
    @Input() originEntityName: string = null;
    @Input() relation: string = null;
    @Input() entityId: number = null;
    @Input() actions: Array<any> = [];
    @Input() loaders: any = {};
    @Input() timestamps = true;
    @Input() runUpdate: Subject<boolean> = new Subject<boolean>();
    @Input() externalData: Subject<boolean> = new Subject<boolean>();
    @Input() fixedSearchParams: any = {};
    @Input() isSelectable = false;
    @Input() external = false;
    @Input() externalMetaData: any;
    @Input() preserveSelection: any;
    @Input() enableHyperLink = true;
    @Input() smallCells = false;
    @Input() refreshExternalData: Subject<any> = new Subject<any>();
    @Input() isEmployeeFigures = false;
    @Input() showSearchFields = true;
    @Input() columnsToDisplay: string[] = [];
    @Input() customColumnNames: any = {};
    @Input() sortFields: { field: string, asc: boolean };

    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() loadExternalData: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChecked: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCheckedAll: EventEmitter<any> = new EventEmitter<any>();
    @Output() onResponse: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedUrl = new EventEmitter<any>();
    private entityName$ = new Subject<string>();
    public searchForm = new UntypedFormGroup({});
    private page$ = new Subject<number>();
    public response: any;
    public entityDescription: EntityDescription;
    public currentPage  = 1;
    public loading: boolean;
    public noDataMessage = 'No data to display.';
    public orderField: string;
    public orderAsc: boolean;
    public allSelected: boolean;
    private snakeCase = snakeCase;
    private externalTerms: any;
    private refreshExternalDataSubscription: Subscription;
    private translationSub: Subscription

    constructor(
        private formBuilder: UntypedFormBuilder,
        private service: EntitiesService,
        private selectedInstancesService: SelectedInstancesService,
        private transService : TranslationService,
        private tokenService: FileTokenService
    ) {}
    private resetOrdering() {
        if(this.sortFields?.field && this.sortFields.hasOwnProperty('asc')) {
            this.orderField = this.sortFields.field;
            this.orderAsc = this.sortFields.asc;
        } else if (!this.external) {
            this.orderField = 'updated_at';
            this.orderAsc = false;
        } else {
            this.orderField = 'id'
            this.orderAsc = true;
        }
    }
    public ngOnInit() {
        this.loadData();
        this.translationSub= this.transService.onTranslationSaved.subscribe((companyId)=> {
            if(this.entityId == companyId) {
                this.loadData();
            }
        })
    }
    public loadData() {
        if (this.refreshExternalData)  {
            this.refreshExternalDataSubscription = this.refreshExternalData.subscribe(() => this.refresh())
        }

        this.allSelected = false
        const entityNameObservable = this.entityName$.pipe(
                                        startWith(this.entityName),
                                        switchMap(entityName => {
                                            if (this.externalMetaData) {
                                                this.service.externalEntityDescriptionsSubject.next(this.externalMetaData)
                                                return this.service.getExternalEntityDescriptionByEntityName(entityName);
                                           }
                                           return this.service.getEntityDescriptionByEntityName(entityName);
                                        }),
                                        tap(entityDescription => {
                                            const form = {};
                                            entityDescription.getFilterableFields().forEach(field => {
                                                const fixedSearchObject = this.fixedSearchParams[field.key]
                                                if (fixedSearchObject) { form[field.key] = this.fixedSearchParams[field.key] } else { form[field.key] = ''; }
                                            });
                                            this.searchForm = this.formBuilder.group(form);
                                            this.page$ = new Subject<number>();
                                            this.resetOrdering();
                                            this.entityDescription = entityDescription;
                                            if(this.customCompanyReportFileView) {
                                              const sourceIndex = this.entityDescription.getFieldsForTable().findIndex(item => item.key === 'source');
                                              if(sourceIndex !== -1){
                                                this.entityDescription.getFieldsForTable()[sourceIndex].show_in_table = false;
                                              }
                                              this.entityDescription.timestamps = [];
                                            }
                                        }),
                                        switchMap(entityDescription => {
                                            return this.searchForm.valueChanges.pipe(debounceTime(400),
                                                                                startWith({}),
                                                                                combineLatest(this.page$.pipe(startWith(1)),
                                                                                (terms, page) => {
                                                                                    return {terms, page};
                                                                                }), );
                                        }),
                                        tap(params => this.currentPage = params.page),
                                        tap(params => this.loading = true),
                                        switchMap(res => {
                                            const terms = { ...this.fixedSearchParams, ...res.terms };
                                            if (this.external) {
                                                this.externalTerms = terms;
                                                const params =  this.service.createExternalEntities(this.entityDescription.name,
                                                    this.externalTerms,
                                                    res.page,
                                                    this.orderField,
                                                    this.orderAsc);
                                                this.loadExternalData.emit(params);
                                                return this.externalData;
                                            }
                                            if (this.relation) {
                                                const relation = this.isEmployeeFigures ? '' : this.relation;
                                                return this.service.searchRelation(this.originEntityName,
                                                                                    this.entityId,
                                                                                    relation,
                                                                                    terms,
                                                                                    res.page,
                                                                                    this.orderField,
                                                                                    this.orderAsc);
                                            }
                                            return this.service.searchEntities(this.entityDescription.name,
                                                                                terms,
                                                                          {
                                                                                    page: res.page,
                                                                                    order_by: this.orderField,
                                                                                    order_asc: this.orderAsc
                                                                                  });
                                        }),
                                        switchMap(instance => {

                                            if (this.selectedInstancesService.selectedInstanceIds && instance.data) {
                                                this.handleSelectedInstances(instance, this.selectedInstancesService.selectedInstanceIds.projectInstanceTable)
                                            }
                                            if (this.selectedInstancesService.instancesMetadata && instance.data) {
                                                this.handleSelectedInstances(instance, this.selectedInstancesService.instancesMetadata.projectInstanceTable)
                                            }
                                            return of(instance)
                                        }))
                                        .subscribe(res => {
                                            if (this.entityName === 'Owner') {
                                                this.formatOwnerData(res);
                                            } else {
                                                this.response = res;
                                                this.onResponse.emit(res);
                                            }
                                            this.loading = false;
                                        }, err => {
                                            console.log(err);
                                            this.loading = false;
                                        });
    }
    ngOnDestroy(): void {
        this.refreshExternalDataSubscription.unsubscribe()
        this.translationSub.unsubscribe()
    }

    handleSelectedInstances(instance, selectedInstances) {
        let selectedCounter = 0
        instance.data.forEach(item => {
            if (selectedInstances.indexOf(item.id) > -1) {
                item.selected = true
                selectedCounter++
            }
        });
        if (selectedCounter === instance.data.length) { this.allSelected = true } else { this.allSelected = false }
    }

    public formatOwnerData(res) {
        if (res && res.data) {
            res.data.forEach(owner => {
                owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
                owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
            });
        }
        this.response = res;
    }
    public doAction(name, entity) {
        this.onAction.emit({ entity, action: name });
    }
    public ngOnChanges(event) {
        if (event.runUpdate) {
            this.runUpdate.subscribe(res => {
                this.page$.next(1);
            });
        }
        if (event.entityName) {
            this.entityName$.next(this.entityName);
        }
    }
    public changePage(event) {
        this.page$.next(event);
    }
    public fieldCanBeOrdered(field) {
        return field.key.indexOf('.') < 0;
    }
    public orderBy(field, asc) {
        this.orderField = field;
        this.orderAsc = asc;
        this.page$.next(1);
    }
    selectAll () {
        if (this.allSelected) {
            this.onCheckedAll.emit(this.response.data)
            this.response.data.forEach(entity => {
                entity.selected = true;
                this.selectRow(entity);
            });
        } else {
            this.onCheckedAll.emit([])
            this.response.data.forEach(entity => {
                entity.selected = false;
                this.selectRow(entity);
            });
        }
    }
    isAllSelected () {
        const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
        if (!selectedObject) {
            return false;
        } else {
            let allSelected = true;
            if (this.response && this.response.data) {
                this.response.data.forEach(entity => {
                if (!this.isChecked(entity.id)) {
                    allSelected = false;
                }
            });
            }
            if (allSelected) {
                return true;
            } else {
                return false;
            }
        }
    }
    public selected(entity) {
        this.onChecked.emit(entity)
    }
    public selectRow (entity) {

        let selectedObject = this.selectedInstancesService.selectedInstanceIds// JSON.parse(localStorage.getItem('selectedItems'));
        if (!selectedObject) {
            selectedObject = {projectInstanceTable: []};
        }
        let selectedObjectDetails = this.selectedInstancesService.instancesMetadata// JSON.parse(localStorage.getItem('selectedItemDetails'));
        if (!selectedObjectDetails) {
            selectedObjectDetails = {projectInstanceTable: []};
        }
        if (entity.selected) {
            if (selectedObject[this.entityName] !== undefined && selectedObject[this.entityName].indexOf(entity.id) === -1) {
                selectedObject[this.entityName].push(entity.id);
            } else {
                selectedObject[this.entityName] = [entity.id];
            }

            if (selectedObjectDetails[this.entityName] !== undefined &&
                selectedObjectDetails[this.entityName].findIndex( ent => ent.id === entity.id) === -1) {
                selectedObjectDetails[this.entityName].push(entity);
            } else {
                selectedObjectDetails[this.entityName] = [entity];
            }
        } else {
            if (selectedObject[this.entityName] !== undefined) {
                const index = selectedObject[this.entityName].indexOf(entity.id);
                if (index > -1) {
                    selectedObject[this.entityName].splice(index, 1);
                }
            }
            if (selectedObjectDetails[this.entityName] !== undefined) {
                const index = selectedObjectDetails[this.entityName].findIndex(ent => ent.id === entity.id);
                if (index > -1) {
                    selectedObjectDetails[this.entityName].splice(index, 1);
                }
            }
        }
        this.handleSelectedInstances(this.response, selectedObject.projectInstanceTable)
        this.selectedInstancesService.addInstanceMetadata(selectedObjectDetails)
        this.selectedInstancesService.addselectedInstanceIds(selectedObject)
        // localStorage.setItem('selectedItems', JSON.stringify(selectedObject));
        // localStorage.setItem('selectedItemDetails', JSON.stringify(selectedObjectDetails));
    }
    public setSelection (data) {
        data.forEach(entity => {
            entity.selected = this.isChecked(entity.id);
        });
    }
    public isChecked (id: number) {
        const selectedObject = JSON.parse(localStorage.getItem('selectedItems'));
        if (selectedObject && selectedObject[this.entityName]) {
            if (selectedObject[this.entityName].indexOf(id) > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    public refresh() {
        if (this.external) {
            this.loading = true;
            const params =  this.service.createExternalEntities(
                this.entityDescription.name,
                this.externalTerms, 1,
                this.orderField, this.orderAsc);
            this.loadExternalData.emit(params);
            return;
        }
        this.page$.next(1);
    }

    public openTranslation(entity) {
        this.transService.onDescriptionSaved(entity);
    }

    public canTranslate(entity) {
        return this.entityName == "Description" && entity.language.name == "English";
    }

  emitPreviewUrl(entity) {
    this.tokenService.getToken().
      pipe(take(1)).subscribe(token => {
        const previewUrl = ApiSettings.BASE_URL + '/file/preview/' + entity.source + '?token=' + token.token;
        this.selectedUrl.emit(previewUrl);
      });
  }

  getAttachmentsAsList(entity) {
    return entity.attachments.map(item => item.source);
  }
}

import { Injectable } from '@angular/core';
import { InstanceDefinition } from 'app/doc-process/sub-modules/doc-process-common/models/fields';

@Injectable({
  providedIn: 'root'
})
export class DataExtractorService {
  constructor() { }

  public getInstanceDefinition(instanceDefinitions: Array<InstanceDefinition>, instanceId: number): InstanceDefinition {
    return instanceDefinitions.find(instanceDefinition => instanceDefinition.InstanceId === instanceId)
  }

  public getInstanceId(instanceDefinitions: Array<InstanceDefinition>, pageIndex: number): number {
    return instanceDefinitions[pageIndex].InstanceId
  }
}

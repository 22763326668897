import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'app/auth/services/profile.service';
import { DocProcessService } from 'app/doc-process/sub-modules/doc-process-common/services/doc-process.service';
import { Subscription } from 'rxjs';
import { Query } from '../../../sub-modules/doc-process-common/services/methods.service';
import { HTTPMethod } from '../../../sub-modules/doc-process-common/models/api';
import { ServerChooserService } from '../../../sub-modules/doc-process-common/services/server-chooser.service';
import { SelectedProjectService } from '../../../sub-modules/universal-data-entry/services/selected-project.service';
import { EnvSettingsService } from '../../../sub-modules/doc-process-common/services/env-settings.service';
import { UtilService } from '../../../sub-modules/doc-process-common/services/util.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'con-doc-process-layout',
  templateUrl: './doc-process-layout.component.html',
  styleUrls: ['./doc-process-layout.component.scss'],
})
export class DocProcessLayoutComponent implements OnInit, OnDestroy {
  loading = false;

  private hasChangedServerSub: Subscription;
  private routerEventSub: Subscription;

  get projectNameHumanFriendly(): string {
    return EnvSettingsService.getEnvironmentHumanFriendlyName(this.serverChooserService.getBackendEnvName());
  }

  get projectColor(): string {
    return EnvSettingsService.getEnvironmentColor(this.serverChooserService.getBackendEnvName());
  }

  constructor(
    public serverChooserService: ServerChooserService,
    public profile: ProfileService,
    public selectedProjectService: SelectedProjectService,
    private docProcessService: DocProcessService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.hasChangedServerSub = this.serverChooserService.hasChangedServer.subscribe((hasChanged) => {
      this.loading = true;
      if (hasChanged) {
        this.docProcessService.getData({ params: 1 }, Query.GetProjects, HTTPMethod.Get);
      }
    });

    if (!!this.serverChooserService.getBackendEnvName()) {
      this.docProcessService.getData({ params: 1 }, Query.GetProjects, HTTPMethod.Get);
    }

    this.docProcessService.apiResponse.subscribe((res) => {
      if (res.type === Query.GetProjects) {
        this.onGetProjects(res);
      }
    });

    this.routerEventSub = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      UtilService.logIfAdmin('selectedProjectService.updateProjectUsingWindow called');
      this.selectedProjectService.updateProjectUsingWindow(window);
    });
  }

  private onGetProjects(res: { data: any; type: any }) {
    this.selectedProjectService.projectsList = res.data;
    this.loading = false;
    this.selectedProjectService.updateProjectUsingWindow(window);
  }

  ngOnDestroy() {
    this.hasChangedServerSub.unsubscribe();
    this.routerEventSub.unsubscribe();
  }

  onHelpIconClick() {
    this.docProcessService.onHelpIconClick();
  }

  getBackendEnvName(): string {
    return this.serverChooserService.getBackendEnvName();
  }
}

import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DocProcessLayoutComponent} from './views/components/doc-process-layout/doc-process-layout.component';
import {DocProcessModuleHomeView} from './views/doc-process-module-home-view.component';
import {DataEntryViewRouterComponent} from './views/components/data-entry-view-router/data-entry-view-router.component';
// import { KpiAndKiidModule } from './sub-modules/kpi-and-kiid/kpi-and-kiid.module';
import {DocProcessCommonModule} from './sub-modules/doc-process-common/doc-process-common.module';
import {MatDialogModule} from '@angular/material/dialog';
import {HelpPageComponent} from './views/components/help-page/help-page.component';
import {MarkdownModule} from 'ngx-markdown';
import {UniversalDataEntryModule} from './sub-modules/universal-data-entry/universal-data-entry.module';
import {EntitiesModule} from "../entities/entities.module";
import {FormsModule} from "@angular/forms";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {
  DocProcessTaskView
} from "./sub-modules/doc-process-common/pages/doc-process-task/doc-process-task-view.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EntitiesModule,
    RouterModule,
    NgbModule,
    FormsModule,
    MatDialogModule,
    PdfViewerModule,
    UniversalDataEntryModule,
    DocProcessCommonModule,
    MarkdownModule.forRoot()],
  providers: [MarkdownModule],
  declarations: [DocProcessLayoutComponent,
    DocProcessModuleHomeView,
    DataEntryViewRouterComponent,
    HelpPageComponent,
    DocProcessTaskView],
})
export class DocProcessModule {
}

import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { DocProcessService } from '../../services/doc-process.service';
import {
  EntitiesService,
} from '../../../../../entities/services/entities.service';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HTTPMethod, HTTPResponse } from 'app/doc-process/sub-modules/doc-process-common/models/api';
import { Query } from 'app/doc-process/sub-modules/doc-process-common/services/methods.service';
import { Instance } from 'app/doc-process/sub-modules/universal-data-entry/models/Typings';
import { SelectedInstancesService } from 'app/doc-process/sub-modules/universal-data-entry/services/selected-instances.service';
import { ValidationTaskDetailTableData } from 'app/doc-process/sub-modules/doc-process-common/models/table-data';
import { MaterialTableComponent } from 'app/doc-process/sub-modules/doc-process-common/components/material-table/material-table.component';
import { TableService } from 'app/doc-process/sub-modules/doc-process-common/services/table.service';
import {Task} from '../../../universal-data-entry/models/Task';

@Component({
  templateUrl: './doc-process-project-view.component.html',
  styleUrls: ['./doc-process-project-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocProcessProjectView<T, D extends T & ValidationTaskDetailTableData> implements OnInit, OnDestroy {

  @ViewChild('projectHomeModal') projectHomeModal: ElementRef;
  @ViewChild('materialTable', {static: true}) materialTable: MaterialTableComponent<T, D>

  public validationStarted = false;
  public taskInstance: Task;
  public loading: boolean;
  public headerName: any;
  public projectId: number;
  public validationTaskHeader = [];
  public projectDetails: any;
  public fetchingData = true;
  public externalData: any;
  public externalMetaData: any[];
  public checkboxItemsFieldDetails = [{ row: 0, checked: false }];
  public checkboxItemsShareClasses = [{ row: 0, checked: false }];
  public projectInfoHeader: string[];
  public projectInfoBody: any;
  public projectDetailTableData: ValidationTaskDetailTableData[];
  public responseMessage: string;
  public instanceEntityName = 'projectInstanceTable';
  public showAlert = false;
  public excludeIds: string;
  public docProcessScubscription: Subscription;
  public tableData$: Subject<any> = new Subject<any>();
  public modalMessage = '';
  public preserveSelection = false;
  public refreshExternalData: Subject<any> = new Subject<any>()
  public fetchingTableData = true;

  constructor(
    private docProcessService: DocProcessService,
    private entitiesService: EntitiesService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private selectedInstancesService: SelectedInstancesService,
    private tableService: TableService,
  ) {}

  ngOnInit() {
    this.docProcessService.getData({}, Query.GetProjects, HTTPMethod.Get);
    this.route.params.subscribe((params) => {
      if (!params.project_id) {
        return
      }
      this.projectId = params.project_id;
      this.docProcessService.getData({params: this.projectId}, Query.GetProjectDetails, HTTPMethod.Get)
      this.refreshExternalData.next()
      this.fetchingTableData = true;
      this.fetchingData = true;
    });
    this.docProcessScubscription = this.docProcessService.apiResponse.subscribe(({type, data}: HTTPResponse) => {
      switch (type) {
        case Query.GetProjectDetails:
          {
            this.projectDetailTableData = data.data;
            this.externalMetaData = data.table_meta;
            this.projectDetails = data;
            this.validationTaskHeader = [...new Set(data.data.map(data => Object.keys(data)).flat())].map(item => capitalize((item as string).replace(/_/g, ' ')));
            this.projectInfoHeader = this.createIProjectInfoDataFieldsHeader();
            this.projectInfoBody = [
              {
                project_id: data.project_id,
                project_name: data.project_name,
                is_production: data.is_production,
              },
            ];
            this.headerName = data.project_name;
            this.fetchingData = false;
            this.fetchingTableData = false;
            setTimeout(() => {this.tableService.changedTableData.next(true)}, 0)
          }
          break;
        case Query.GetProjectInstances: {
            this.externalData = data;
            this.tableData$.next(this.externalData);
          }
          break;
        case Query.CreateValidationTask:
          {
            if (data.state === "success") {
              const responseMessage = "Task created with id: " + data.return.task_id;
              this.toastr.success(responseMessage, 'Create task');
              this.router.navigate(['task', data.return.task_id], {
                relativeTo: this.route,
              })
            }
            if (data.state === "error") {
              const responseMessage = data.exception;
              this.toastr.error(responseMessage, 'Create task');
            }
          }
          break;
      }
    });
  }

  ngOnDestroy() {
    this.docProcessScubscription.unsubscribe();
    this.selectedInstancesService.addselectedInstanceIds(null)
    this.selectedInstancesService.addInstanceMetadata(null)
  }

  loadData(event): void {
    this.docProcessService.getData({params: this.projectId, options: event}, Query.GetProjectInstances, HTTPMethod.Get)
  }

  createInstancesHeader(instance: Instance) {
    return Object.keys(instance).map((key) => key);
  }

  createIProjectInfoDataFieldsHeader(): Array<string> {
    return ['Project ID', 'Project Name', 'Production'];
  }

  onTaskIdClick(event: ValidationTaskDetailTableData): void {
    this.navigateToTask(event);
  }

  private navigateToTask(task: ValidationTaskDetailTableData): void {
    this.router.navigate(['task', task.id], {
      relativeTo: this.route,
    });
  }

  createValidation(confirm?: boolean) {

    this.excludeIds = '';
    const selectedInstances =       this.selectedInstancesService.selectedInstanceIds;
    const selectedInstanceDetails = this.selectedInstancesService.instancesMetadata;


    if (selectedInstances.projectInstanceTable.length === 0) {
      this.toastr.error('Please select atleast one instance!', 'Create task');
      return;
    }
    const excludedInstances = [];
    const includedInstances = [];
    selectedInstances.projectInstanceTable.forEach(instance => {
      const index = selectedInstanceDetails.projectInstanceTable.findIndex( (inst) => {return inst.id === instance});
      if (index > -1) {
        if (selectedInstanceDetails.projectInstanceTable[index].task_id) {
          excludedInstances.push(instance);
        } else {
          includedInstances.push(instance);
        }
      }
    });
    if (includedInstances.length === 0) {
      this.toastr.error('The selected instances are already associated with another task!', 'Create task');
      return;
    }
    if (excludedInstances.length > 0 && !confirm) {
      this.modalMessage = excludedInstances.length === 1 ?
         'The instance with following Id is already associated with another task and will be excluded from the current instance list:' :
         'The instances with following Ids are already associated with another task and will be excluded from the current instance list :';
      this.excludeIds = excludedInstances.join(', ');
      this.openModal();
      return;
    }
    const user = JSON.parse(localStorage.getItem('USER-DETAILS'));
    const payload = {
      project_id: this.projectId,
      user_assigned: user.email,
      instance_ids: includedInstances,
    };

    this.docProcessService.getData({params: this.projectId, body: payload}, Query.CreateValidationTask, HTTPMethod.Post);

    this.selectedInstancesService.addselectedInstanceIds(null)
    this.selectedInstancesService.addInstanceMetadata(null)
  }

  openModal() {
    this.modalService.open(this.projectHomeModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.createValidation(true);
    }, (reason) => {
      return;
    });
  }
}


const capitalize = (s) => {
  if (typeof s !== 'string') { return '' }
  return s.charAt(0).toUpperCase() + s.slice(1)
}
